@font-face {
  font-family: 'queenofclubs';
  src: url('queenofclubs.eot?39800224');
  src: url('queenofclubs.eot?39800224#iefix') format('embedded-opentype'),
       url('queenofclubs.woff2?39800224') format('woff2'),
       url('queenofclubs.woff?39800224') format('woff'),
       url('queenofclubs.ttf?39800224') format('truetype'),
       url('queenofclubs.svg?39800224#queenofclubs') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'queenofclubs';
    src: url('queenofclubs.svg?39800224#queenofclubs') format('svg');
  }
}
*/
[class*="qoc-"]:before {
  font-family: "queenofclubs";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  /*line-height: 1em;*/

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.qoc-apartment:before { content: '\e800'; } /* '' */
.qoc-arrow:before { content: '\e801'; } /* '' */
.qoc-attraction:before { content: '\e802'; } /* '' */
.qoc-boattransfer:before { content: '\e803'; } /* '' */
.qoc-boat:before { content: '\e804'; } /* '' */
.qoc-boatasdirected:before { content: '\e805'; } /* '' */
.qoc-asdirectedcar:before { content: '\e806'; } /* '' */
.qoc-chat:before { content: '\e808'; } /* '' */
.qoc-coach:before { content: '\e809'; } /* '' */
.qoc-contact:before { content: '\e80a'; } /* '' */
.qoc-creditcard:before { content: '\e80b'; } /* '' */
.qoc-download:before { content: '\e80c'; } /* '' */
.qoc-driverguide:before { content: '\e80d'; } /* '' */
.qoc-experience:before { content: '\e80e'; } /* '' */
.qoc-flight:before { content: '\e80f'; } /* '' */
.qoc-guide:before { content: '\e810'; } /* '' */
.qoc-helicopters:before { content: '\e811'; } /* '' */
.qoc-hotel:before { content: '\e812'; } /* '' */
.qoc-miscellaneous:before { content: '\e813'; } /* '' */
.qoc-jets:before { content: '\e814'; } /* '' */
.qoc-restaurant:before { content: '\e815'; } /* '' */
.qoc-share:before { content: '\e816'; } /* '' */
.qoc-sport:before { content: '\e817'; } /* '' */
.qoc-guidedtour:before { content: '\e818'; } /* '' */
.qoc-tax:before { content: '\e819'; } /* '' */
.qoc-theatre:before { content: '\e81a'; } /* '' */
.qoc-tickets:before { content: '\e81b'; } /* '' */
.qoc-touroperator:before { content: '\e81c'; } /* '' */
.qoc-train:before { content: '\e81d'; } /* '' */
.qoc-transfer:before { content: '\e81e'; } /* '' */
.qoc-villa:before { content: '\e81f'; } /* '' */
.qoc-vipassistance:before { content: '\e820'; } /* '' */
.qoc-carrental:before { content: '\e821'; } /* '' */
