@import "../../Mixins";

.BlockImage {
  position: relative;
  &.editable {
    img {
      opacity: .3;
    }
  }
  .caption-container {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(0,0,0,0), black);
    color: white;
    text-align: right;
    padding: 0.75rem;
    height: auto;
    transition: opacity 0.3s ease-in-out;

    @include respond-to('medium') {
      &.hidden {
        opacity: 0;
      }
      &.show {
        opacity: 1;
      }
    }

    .caption {
      position: absolute;
      bottom: 2px;
      right: 5px;
      cursor: default;
    }
  }
}
