@import "../../Mixins.scss";

.PdfModal {
  .modal-content {
    border-radius: 4px;
    background: var(--unnamed-color-f7f7f7) 0% 0% no-repeat;

    .modal-header {
      border-bottom: none;
      .modal-title {
        font-size: 1.2rem;
        font-weight: 600;
      }

      .btn-close {
        &:focus, &:focus-visible, &:active, &:hover {
          outline: none;
          box-shadow: none;
        }
      }
    }

    .pdfmodal-separator {
      background: linear-gradient(to right, transparent, #282828, transparent);
      width: 90%;
      height: 1px;
      margin: auto;
    }

    .modal-body {

      @include respond-to('small') {
        padding-top: 0;
      }

      .pdfmodal-btns-list {
        padding: 0;
        list-style: none;


        li {
          width: 100%;
          text-align: left;
          font-weight: 300;

          a {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            margin-top: 10px;
            border-radius: 35px;

            .icon {
              display: inline-flex;
              width: 15%;
              .qoc-icon:before {
                font-size: 2rem;
                margin-top: -15px;
              }
            }

            .text {
              width: 85%;
              flex-shrink: 1 !important;

              .country {
                font-weight: 500;
              }
            }


            &:hover {
              background: var(--unnamed-color-131731) 0% 0% no-repeat;
              color: var(--unnamed-color-f7f7f7);
              cursor: pointer;
            }

            @include respond-to('small') {
              background: var(--unnamed-color-131731) 0% 0% no-repeat;
              color: var(--unnamed-color-f7f7f7);
              padding: 2px;
              border-radius: 5px;
            }
          }
        }
      }
    }
  }
}