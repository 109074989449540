.Iconset {
  display: block!important;
  align-items: center;
  padding: 0px;

  .span3 {
    display: block;
    margin-top: 1rem;
    border-bottom: 1px solid #ccc;
    padding-bottom: 2rem;
  }

  i {
    font-size: 4rem;
  }

  .i-code {
    display: none;
  }
}
