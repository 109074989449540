@import '../Mixins.scss';

.WelcomeLetter {
  background: var(--unnamed-color-f7f7f7) 0 0 no-repeat padding-box;
  display: flex;
  flex-direction: column;
  z-index: 2; // Must be greater than the z-index of the mobile top and bottom navs
  @include respond-to('medium') {
    flex-direction: row;
    min-height: 100vh;
  }


  .left {
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 10%;
    width: 100%;
    flex-direction: row;
    @include respond-to('medium') {
      padding: 0 !important;
      width: 30%;
      padding-left: 5vw;
      flex-direction: column !important;
      justify-content: space-between !important;
    }

    .wltitle {
      font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-14) var(--unnamed-font-family-cabinet-grotesk);
      letter-spacing: var(--unnamed-character-spacing-0-28);
      color: var(--unnamed-color-131731);
      width: 25%;
      @include respond-to('medium') {
        width: 100%;
        padding-top: 25%;
        padding-left: 20%;
        font-size: 18px;
        line-height: 20px;
      }
    }

    .logo {
      text-align: right;
      width: 75%;

      img {
        width: 25%;
      }

      @include respond-to('medium') {
        width: 100%;
        padding-bottom: 25%;
        padding-left: 20%;
        text-align: left;
        img {
          width: 25%;
        }
      }
    }
  }


  .right {
    width: 100%;
    @include respond-to('medium') {
      width: 60%;
      margin-top: 5%;
    }
  }

  .dear {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 35px/var(--unnamed-line-spacing-14) var(--unnamed-font-family-creattion);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-131731);
    text-align: left;
    @include respond-to('medium') {
      font-size: 60px;
    }
  }

  .welcome_text {
    margin-top: 50px;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-18)/var(--unnamed-line-spacing-22) var(--unnamed-font-family-georgia);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-131731);
    text-align: left;
    @include respond-to('medium') {
      margin-top: 10%;
      font-size: 25px;
      line-height: 30px;
    }
    @include respond-to('large') {
      margin-top: 5%;
      font-size: 25px;
      line-height: 50px;
      width: 80%;
    }
  }

  .signature {
    text-align: right;
    margin-top: 4rem;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 28px/29px var(--unnamed-font-family-creattion);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-131731);
    @include respond-to('medium') {
      font-size: 2.5rem;
    }
    @include respond-to('large') {
      margin-top: 10rem;
      font-size: 2.5rem;
    }

    .sincerely {
      padding-right: 6rem;
      padding-bottom: 1rem;
    }
  }

}
