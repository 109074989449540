@import '../Mixins.scss';
.DayBlock {

    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-cabinet-grotesk);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-131731);

    text-align: left;
    padding: 5%;


    @include respond-to('medium') {
        padding: 5% 7% 5% 3%;
    }

}



