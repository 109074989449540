@import '../Mixins.scss';
@import 'bootstrap/scss/bootstrap.scss';

.Sidebar {
  display: none;

  button, i {
    all: unset;
  }

  @include respond-to('medium') {
    display: block;
    opacity: 0;
    position: sticky;
    height: 0;
    width: 70px;

    top: 25vh;
    left: 100vw;
    transition: all 0s ease-out;
    margin-right: 1%;
    &.active {
      position: sticky;
      opacity: 1;
      z-index: 100000;
      .cont {
        height: 100vh;
      }
    }

    .cont {
      height: 0;
      display: flex;
      flex-direction: column;
      transition: height .2s ease-in-out;
      //border-left: 1px solid red!important;


      .iconbutton {
        font-size: 2rem;
        width: 100%;
        @include respond-to('large') {
          font-size: 3rem;
        }
      }

      .hamburgerbar {
        display: flex;
        .iconbutton {
          width: 30px;
          margin-left: 20px;
        }

      }

      .buttonbar {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
      }
    }
  }
}

