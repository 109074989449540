@import "../../Mixins";

.BlockNotes {
  font-size: 16px;
  text-align: left;
  letter-spacing: 0;
  color: #131731;

  @include respond-to('medium') {
    padding: 20px;
  }

  .blocknotes-title {
    font-weight: bold;
  }
}