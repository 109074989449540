@import '../Mixins.scss';
.Splash {

    background: var(--unnamed-color-0b0e1d);
    width: 100%;
    display: flex;
    height: 100vh;
    justify-content: center !important;
    z-index: 2; // Must be greater than the z-index of the mobile top and bottom navs


    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(14, 36, 54, .9); /* Adjust blue color and opacity as needed */
        pointer-events: none; /* This ensures that the overlay does not interfere with clicking or interacting with other elements */
        @extend .filter-lens !optional;
        @extend .filter-blending !optional;
    }


    .cont {
        z-index: 1;
        width: 100%;
        display: flex;
        justify-content: center !important;
    }

    .agencyLogo {
        position: absolute;
        top: 0;
        display: flex;
        flex-direction: column;
        background-color: white;
        min-width: 20%;
        min-height: 15%;
        padding-bottom: 20px;
        justify-content: flex-end;
        img {
            max-width: 90%;
            height: auto;
            object-fit: contain;
        }
        @include respond-to('medium') {
            min-width: 8%;
            max-width: 10%;
            min-height: 15%;
        }
    }

    .bg {
    }

    .customerBox {
        top:40%;
    }

    .customer {
        font: italic normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-64)/var(--unnamed-line-spacing-64) var(--unnamed-font-family-georgia);
        letter-spacing: var(--unnamed-character-spacing--1-28);
        color: var(--unnamed-color-f7f7f7);
        text-align: center;

        @include respond-to('medium') {
            margin-top: 20px;
            font: italic normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-80)/var(--unnamed-line-spacing-80) var(--unnamed-font-family-georgia);
            letter-spacing: var(--unnamed-character-spacing--1-6);
        }
    }

    .association {
        font: var(--unnamed-font-style-normal) normal medium var(--unnamed-font-size-18)/var(--unnamed-line-spacing-22) var(--unnamed-font-family-cabinet-grotesk);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-f7f7f7);
        text-align: center;
        @include respond-to('medium') {
            font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-cabinet-grotesk);
            letter-spacing: var(--unnamed-character-spacing-0);
        }
    }

    .best {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-28)/var(--unnamed-line-spacing-28) var(--unnamed-font-family-georgia);
        letter-spacing: var(--unnamed-character-spacing--0-28);
        color: var(--unnamed-color-f7f7f7);
        text-align: center;
        text-transform: uppercase;
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
        .destination {
            font-style: italic;
        }
        @include respond-to('medium') {

        }
    }

    .dates {
        font: var(--unnamed-font-style-normal) normal medium var(--unnamed-font-size-18)/var(--unnamed-line-spacing-22) var(--unnamed-font-family-cabinet-grotesk);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-f7f7f7);
        text-align: center;
        margin-top: 20px;
    }

    .splashbtns {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 0px;
        @include respond-to('medium') {
            padding-bottom: 10px;
        }

        .splashbtn {
            display: block;
            border-radius: 100px;
            border: 1px solid var(--unnamed-color-f7f7f7);
            opacity: 1;
            padding: 1px 10px;
            font: var(--unnamed-font-style-normal) bold var(--unnamed-font-weight-800) var(--unnamed-font-size-12)/var(--unnamed-line-spacing-14);
            letter-spacing: var(--unnamed-character-spacing-0-28);
            color: var(--unnamed-color-ffffff);
            text-align: center;
            font-weight: 600;
            text-transform: uppercase;
            text-decoration: none;
            background: transparent;
        }

        .splashbtn.active, .splashbtn:hover {
            background: var(--unnamed-color-f7f7f7) 0% 0% no-repeat;
            color: var(--unnamed-color-131731);
        }

        .splashbtns-list {
            position: absolute;
            top: 1.75rem;
            left: 11px;
            right: 11px;
            background: var(--unnamed-color-f7f7f7) 0% 0% no-repeat;
            padding: 0;
            list-style: none;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;

            li {
                width: 100%;
                text-align: left;
                padding: 5px;
                font-weight: 500;
                &:hover {
                    background: var(--unnamed-color-131731) 0% 0% no-repeat;
                    color: var(--unnamed-color-f7f7f7);
                }
            }

            &.show {
                display: block;
            }

            &.hide {
                display: none;
            }
        }
    }
}

