@import '../Mixins.scss';
.DayAffix {
  display: none;
  color: white!important;

  @include respond-to('medium') {
    display: block;
    position: absolute;
    background: #fff;
    width: 80px;
    height: 0px;
    left: 30px;
    transition: none;
    z-index: 100000;
    opacity: 0;

    &.active {
      position: sticky;
      transition: all .60s ease;
      opacity: 1;
      top: 40vh;
    }

    .cont {
      li {
        font-size: 15px;
        &:hover {
          font-weight: 800;
          text-shadow:1px 1px 10px #fff, 1px 1px 10px #ccc;
          a {
            opacity: 1;
          }
        }
        a {
          opacity: 0;
        }
        &.active {
          font-weight: 800;
          text-shadow:1px 1px 10px #fff, 1px 1px 10px #ccc;
          a {
            opacity: 1;
          }
        }
      }
    }
  }


  .active {
  }
}
