@import '../Mixins.scss';
.MobileNavTop {
    display: flex;
    flex-direction: column;
    position: sticky;
    background-color: var(--unnamed-color-0b0e1d);
    transition: all 0.5s ease-in-out;
    opacity: 1;
    top: 0!important;
    padding: 40px 20px 10px;
    height: 116px;
    z-index: 1;
    margin-top: -116px;

    @include respond-to('medium') {
        display: none !important;
    }

    //&.active {
    //    position: sticky;
    //    opacity: 1;
    //    top: 0!important;
    //    padding: 10px 20px 10px;
    //    padding-top: 40px;
    //    height: auto;
    //}

    .top{
        display: flex;
        .logo {
            display: flex;
            padding-right: 10px;

            width: 100px;
            border-right: 1px solid white;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .affix::-webkit-scrollbar {
            display: none; /* Hide scrollbar for Chrome, Safari and Opera */
        }
        .affix::-webkit-scrollbar-thumb:horizontal {
            display: none;
        }
        .affix {
            overflow: auto;
            overflow-x: auto;
            white-space: nowrap;
            -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
            scrollbar-width: none; /* Hide scrollbar for Firefox */
            height: 30px;
            ul {
                display: block;
                padding-left: 1rem;
                padding-bottom: .02rem;
                &::after {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    height: 100%;
                    width: 100px; /* Width of the gradient effect */
                    background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
                    pointer-events: none; /* Allows clicks to pass through to the scrollbar */
                }
                list-style: none;
                li {
                    display: inline-flex;
                    margin-right:5px;
                    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-14) var(--unnamed-font-family-cabinet-grotesk);
                    letter-spacing: var(--unnamed-character-spacing-0-28);
                    color: var(--unnamed-color-bec8da);
                    padding: .2rem .7rem;
                    padding-top: .2rem;
                    &.active {
                        border: 1.5px solid var(--unnamed-color-f7f7f7);
                        border: 2px solid #F7F7F7;
                        border-radius: 100px;
                    }
                }
            }
        }
    }


    // where the dates reside
    .bottom{
        display: flex;
        padding-top: 5px;
        padding-left: 10px;
        margin-top: 10px;
        border-top: 1px solid white;
        height: 40%;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-cabinet-grotesk);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-f7f7f7);

    }
}
