@import 'Mixins.scss';
// Import fonts
@import url('fonts/cabinet-grotesk.css');
@import url('fonts/queenofclubs.css');
@import url('fonts/creattion.css');

$enable-cssgrid: true;
// bs 5
@import 'bootstrap/scss/bootstrap.scss';

// vars
@import './variables.scss';
@import 'instagram.css/src/instagram.scss';

@import "components/Iconset.scss";

html, body {
  height: 100%;
  margin: 0;
  //overflow-x: hidden;
  //overflow-y: auto;
}

html {
  overflow-x: hidden;
}

a {
  text-decoration: none !important;
  color: inherit;
}

html {
  //scroll-behavior: smooth;
}

body {
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.filter-lens {
  //@extend .filter-moon;
  //filter: contrast(1.1) brightness(1);
}

.filter-blending {
  mix-blend-mode: darken;
}



@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";


@import url('components/Splash.scss');
@import url('components/GlanceImage.scss');
@import url('components/GlanceText.scss');
@import url('components/WelcomeLetter.scss');
@import url('components/ImageBlock.scss');
@import url('components/DayBlock.scss');
@import url('components/DayAffix.scss');
@import url('components/SideBar.scss');
@import url('components/Terms.scss');
@import url('components/Gratuities.scss');
@import 'components/VersionChangeAlert.scss';
@import "components/Loading.scss";
@import "components/Hamburger.scss";
@import "components/Message.scss";

.active-scroll-spy {
  background-color: yellowgreen;
  border-radius: 15px;
}

.AtGlance {
  @extend .d-inline-flex;
  @extend .row;
  @extend .g-0;
}




.fullsize {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}


.bordered {
  border: 1px solid #fff;
}


.collapsing {
  transition: height .35s ease;
}

.colsize {
  width: 100%;
}



.ItineraryTool {
  @extend .d-flex;
  @extend .flex-column;

  &.active {
  }
}


.ItineraryContainer {
}




// do not display in mobile. only desktop
.LastRow {
  display: flex;
  flex-direction: column;
  margin-bottom: 80px; // Same height as the bottom nav
}


.Textblock {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-cabinet-grotesk);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-131731);
  text-align: left;
  @include respond-to('medium') {
    min-height: 100vh;
  }

  .remark {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-cabinet-grotesk);
    letter-spacing: var(--unnamed-character-spacing-0-32);
    color: var(--unnamed-color-131731);
    text-align: left;
    margin-bottom: 1rem;

    &.title {
      text-transform: uppercase;
    }
  }

  &.Leftblock {
    @include respond-to('medium') {
      padding-left: 12%;
      padding-right: 5%;
    }
  }

  &.Rightblock {
    @include respond-to('medium') {
      padding-right: 5%;
      padding-left: 5%;
    }
  }

}


/*media queries*/

/* Medium devices (tablets, 768px and up) */
@include respond-to('medium') {

  // display
  .LastRow {
    flex-direction: row;
    margin-bottom: 0;
  }


  .colsize {
    width: 50%;
    display: inline-flex !important;
    flex-direction: column !important;
  }


}

.ToggleButton {
  background: var(--unnamed-color-bec8da) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-bec8da);
  border-radius: 100px;
  padding: 5px 10px;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-14)/17px var(--unnamed-font-family-cabinet-grotesk);
  letter-spacing: var(--unnamed-character-spacing-0-28);
  color: var(--unnamed-color-131731);
  text-align: center;
}

.RoundedBox {
  background: var(--unnamed-color-f7f7f7) 0% 0% no-repeat padding-box;
  background: #F7F7F7 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding: 1rem;
}

.PropertyTable {


  .row {
    width: 100%;
    padding: 0.5rem 0;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #ccc;

    &:last-child {
    }

    .term {
      width: 50%;
      text-transform: uppercase;
      font-weight: bold;
    }

    .description {
      width: 50%;
      text-align: right;
    }
  }
}




