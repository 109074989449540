@import "../../Mixins.scss";

.BlockVideo {
  position: relative;

  &.video-sm {
    height: 380px !important;
  }

  &.video-lg {
    height: 500px !important;
  }

  .blockvideo-wrapper {
    height: 100%;

    animation: none;

    &.pop {

      iframe {
        border-top-right-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.75) 0px 0px 41px -5px
      }

      div {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 675px !important;
        height: 380px !important;
        z-index: 999;
        animation: popVideo 0.5s forwards;
        border-top-right-radius: 5px;

        @include respond-to('small') {
            width: 100% !important;
            height: 190px !important;
            bottom: 40px;
        }
      }
    }

    &.unpopping {
      div {
        animation: unpopVideo 0.5s forwards;
      }
    }
  }
}

@keyframes popVideo {
  0% {
    transform: scale(0);
  }
  70% {
    transform: scale(1.1);
  }
  85% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes unpopVideo {
  0% {
    transform: scale(1);
  }
  15% {
    transform: scale(1.1);
  }
  30% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0);
  }
}

