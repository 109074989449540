@import "../Mixins.scss";

.PropertyTableValue {
  width: 70%;
  text-align: right;

  &.property-table-value-split {
    display: inline-flex;
    justify-content: end;
    gap: 0.25rem!important;
    flex-direction: row;

    @include respond-to('small') {
      flex-direction: column;
      width: 50%;
    }
  }
}