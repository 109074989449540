.BlockSubtitle {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0;
  color: #131731;
  opacity: 1;
  white-space: pre-wrap;
  margin-bottom: 15px;
  div {
    margin-bottom: 10px;
    span {
      font-weight: bold;
    }
  }
}