@import "../Mixins.scss";

.PropertyTable {
  margin: 10px 0;
  text-align: left;
  font-size: 16px;
  letter-spacing: 0.28px;
  color: #131731;
  opacity: 1;

  .property-row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #BEC8DA;
    padding-top: 0.5rem;

    .property-title {
      text-transform: uppercase;
      font-weight: bold;
      width: 30%;

      @include respond-to('small') {
        width: 50%;
        word-wrap: break-word;
      }
    }
  }

}