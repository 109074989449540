.BlockText {
  text-align: left;
  font-size: 16px;
  line-height: 1.5rem;
  letter-spacing: 0;
  color: #131731;
  white-space: pre-wrap;
  margin-bottom: 30px;

  p, ul, ol {
    white-space: normal;
    margin: 1rem 0;
  }
}