@import '../Mixins.scss';
.GlanceText {
    background: var(--unnamed-color-dddddd);
    text-align: left;
    padding: var(--unnamed-padding-5)!important;
    width: 100%;
    z-index: 2; // Must be greater than the z-index of the mobile top and bottom navs

    @include respond-to('medium') {
        width: 50%!important;
        padding: var(--unnamed-padding-10) !important;
    }

    .welcome {
        margin-top: 20px;
        font: italic normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-45)/var(--unnamed-line-spacing-45) var(--unnamed-font-family-georgia);
        letter-spacing: var(--unnamed-character-spacing--0-68);
        color: var(--unnamed-color-131731);
        text-align: left;
        @include respond-to('medium') {
            margin-top: 10px;
            font: italic normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-60)/var(--unnamed-line-spacing-60) var(--unnamed-font-family-georgia);
            letter-spacing: -3px;
        }
    }

    .gla {
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-14) var(--unnamed-font-family-cabinet-grotesk);
        letter-spacing: var(--unnamed-character-spacing-0-28);
        color: var(--unnamed-color-131731);
        text-align: left;
        @include respond-to('medium') {
            margin-top: 5%;
            font-weight: 800;
        }
    }

    .button-bar {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        @include respond-to('medium') {
            margin-top: 20%;
        }

        .button {
            margin: 2px;
            flex-wrap: nowrap;
            border: 1px solid #c2cbdc;
            border-radius: 100px;
            opacity: 1;
            font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-14) var(--unnamed-font-family-cabinet-grotesk);
            letter-spacing: var(--unnamed-character-spacing-0-28);
            color: var(--unnamed-color-131731);
            text-align: center;
            padding: 6px 24px;
            @include respond-to('medium') {
                font-weight: 800;
                font-size: 1.1rem;
            }
        }
    }
    .destinationlist {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        @include respond-to('medium') {

        }

        .destinationlistitem {
            margin-top: 20px;
            margin-bottom: 10px;
            @include respond-to('medium') {
                margin-top: 20px;
                display: flex;
                flex-direction: row;
                width: 100%;
                vertical-align: top;
                border-bottom: 1px solid var(--unnamed-color-bec8da);
            }

            .where {
                font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-28)/var(--unnamed-line-spacing-28) var(--unnamed-font-family-georgia);
                letter-spacing: var(--unnamed-character-spacing--0-28);
                color: var(--unnamed-color-131731);
                text-align: left;

                @include respond-to('medium') {
                    width: 40%;
                    padding-top: 20px;
                    font-size: 2rem;
                }

            }

            .bydateitem {
                margin-bottom: 35px;
                .when {
                    letter-spacing: var(--unnamed-character-spacing-0);
                    color: var(--unnamed-color-131731);
                    text-align: left;
                }

                .what {
                    letter-spacing: var(--unnamed-character-spacing-0);
                    color: var(--unnamed-color-131731);
                    text-align: left;
                    font-size: 1.1rem;
                    // Appending a comma after each item other than the last one
                    .whatitem {
                        &::after {
                            content: ",";
                        }

                        &:last-child {
                            &::after {
                                content: ""; // Remove comma for the last element
                            }
                        }
                    }
                }
            }

            .day {
                font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-cabinet-grotesk);
                letter-spacing: var(--unnamed-character-spacing-0);
                color: var(--unnamed-color-131731);
                text-align: left;
                @include respond-to('medium') {
                    font-size: 1.1rem;
                    font-weight: 800;
                }
            }

            .date {
                font-weight: 300;
            }
        }
    }

    .bydate{
        margin-top: 40px;
        @include respond-to('medium') {
            vertical-align: top;
            margin-top: 0px;
            width: 50%;
            padding-left: 20px;
            padding-top: 0px;
        }
        .bydateitem{
            margin-top: 15px;
        }
    }
}
