@import '../Mixins.scss';
@import 'bootstrap/scss/bootstrap.scss';

.MobileNavBottom {
  --barHeight: 80px;
  position: sticky;
  opacity: 1;
  z-index: 1;
  top: calc(100vh - var(--barHeight) + 0px) !important;
  color: white;
  margin-top: calc(var(--barHeight) * -1);

  button {
    all: unset;
  }

  .wrapper {
    background-color: var(--unnamed-color-0b0e1d);
    height: var(--barHeight);

    .cont {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .iconbutton {
        display: inline-flex;
        font-size: 3rem;

        [class*=qoc-] {
          margin-top: -10px;
        }
      }

      .left {
        padding-left: 30px;
        padding-top: 20px;
      }

      .right {
        justify-content: end;
        display: inline-flex;
        @extend .flex-row;
      }
    }
  }

  // do not display on desktop
  @include respond-to('medium') {
    display: none !important;
  }
}






