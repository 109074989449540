.ComboTransportation {

  .combotransportation-heading {
    display: flex;
    justify-content: space-between;

    .combotransportation-heading-title {
      text-align: left;
      font-size: 20px;
      letter-spacing: 0;
      color: #131731;
      opacity: 1;

      .combotransportation-heading-arrow {
        margin-top: -10px;
        margin-right: 10px;

        i:before {
          //background: #0F1425 0 0 no-repeat padding-box;
          rotate: -90deg;
          opacity: 1;
          font-size: 30px;
        }
      }
    }

    .combotransportation-heading-button {
      border: 1px solid #BEC8DA;
      border-radius: 100px;
      opacity: 1;
      padding: 5px 32px;
      text-align: center;
      font-variant: normal;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      letter-spacing: 0.32px;
      color: #131731;
      text-transform: uppercase;
      cursor: pointer;

      &.show {
        background: #BEC8DA 0 0 no-repeat padding-box;
      }
    }
  }


  .combotransportation-summary {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
    margin-top: 20px;
    gap: 5%;
    padding: 17px 21px 36px 32px;

    .combotransportation-summary-block {
      display: flex;
      flex: 0 0 auto;
      width: 45%;
      margin-top: 5px;
      margin-bottom: 5px;
      flex-direction: column;
      gap: 15px;
      border-bottom: 1px solid #BEC8DA;
      opacity: 1;

      &.compacted {
        gap: 0.25rem;
      }

      .combotransportation-summary-block-title {
        font-weight: bold;
      }
    }
  }

  .combotransportation-steps {
    margin-top: 18px;
    position: relative;
    width: 100%;
    transition: transform 0.3s ease-in-out 0.2s, opacity 0.3s ease-in-out, height 0.3s ease-in-out;
    transform: translateY(-100%);
    z-index: -1;
    opacity: 0;
    height: 0;

    @keyframes shake {
      0% {
        transform: translateY(0);
      }
      10% {
        transform: translateY(1px);
      }
      20% {
        transform: translateY(2px);
      }
      30% {
        transform: translateY(3px);
      }
      40% {
        transform: translateY(4px);
      }
      50% {
        transform: translateY(5px);
      }
      60% {
        transform: translateY(4px);
      }
      70% {
        transform: translateY(3px);
      }
      80% {
        transform: translateY(2px);
      }
      90% {
        transform: translateY(1px);
      }
      100% {
        transform: translateY(0);
      }
    }

    &.show {
      transform: translateY(0%);
      opacity: 1;
      height: 100%;
      transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out 0.2s;
      z-index: 1;

      .combotransportation-step {
        /* Start the shake animation and make the animation last for 0.5 seconds */
        animation: shake 0.5s;
        /* When the animation is finished, start again */
        animation-iteration-count: 1;
        animation-delay: 0.2s;
      }
    }


  }
}
