.Hamburger {
  cursor: pointer;
  display: block !important;

  .hamburger-line {
    border: 0.7px solid #131731;
    opacity: 1;
    margin-bottom: 4px;
  }

  &.small {
    width: 25px;
  }

  &.dark {
    .hamburger-line {
      border-color: white;
    }
  }

}
