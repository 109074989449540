.BlockTransportTitle {
  font-size: 4rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;

  .title {
    text-align: left;
    font-size: 20px;
    letter-spacing: 0;
    color: #131731;
    opacity: 1;
    line-height: 2rem;
  }

  .icon {
    display: block;
    margin-top: -1rem;
    padding: 0;

    i {
      font-size: 4rem;
    }
  }
}
