.BlockSlider {
  .slick-slide {
    max-height: 800px;
  }
  .slick-arrow {
    &:before {
      color: #d9d9d9;
      opacity: 0;
        transition: all 0.3s;
    }
    &:hover {
        &:before {
            opacity: 1;
        }
    }
  }

}