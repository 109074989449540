.Loading {
  @extend .w-100;
  @extend .h-100;
  @extend .min-vh-100;
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-center;
  @extend .flex-column;
  background-color: #f8f9fa00;

  .spinner {

    @extend .d-flex;
    @extend .w-50;
    @extend .h-50;
    @extend .flex-row;
    @extend .align-items-center;
    img {
        @extend .w-100;
        @extend .h-100;
    }
    .loader {
      @extend .w-100;
      height: 80px;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('../loader.svg');
      margin: 50px;
    }
  }

  .message {
    @extend .w-100;
    text-align: center;
  }

}
