@mixin respond-to($breakpoint) {
  @if $breakpoint == 'small' {
    @media (max-width: 767px) { @content; }
  } @else if $breakpoint == 'medium' {
    @media (min-width: 1057px) { @content; }
  } @else if $breakpoint == 'large' {
    @media (min-width: 1200px) { @content; }
  } @else if $breakpoint == 'x-large' {
    @media (min-width: 1900px) { @content; }
  }
}



//@mixin respond-to($breakpoint) {
//  @if $breakpoint == 'small' {
//    @media (max-width: 767px) { @content; }
//  } @else if $breakpoint == 'medium' {
//    @media (min-width: 768px) { @content; }
//  } @else if $breakpoint == 'large' {
//    @media (min-width: 992px) { @content; }
//  } @else if $breakpoint == 'x-large' {
//    @media (min-width: 1200px) { @content; }
//  }
//}
