@import "BlockText";

.BlockText {
  &[contenteditable="true"] {
    outline-offset: 5px;

    transition: outline-color 0.5s ease;

    &.success {
      outline: #208a20 auto 1px !important;
    }

    &.error {
      outline: #dc4d4d auto 1px !important;
    }

    &:not(:focus-visible) {
      outline: #d3d3d3ff auto 1px;
    }
  }
}