.BlockTitle {
  font-size: 4rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;

  .title {
    font: normal normal bold 30px/32px Georgia;
    text-align: left;

    letter-spacing: -0.3px;
    color: #131731;
    opacity: 1;
    line-height: 2rem;
  }

  .icon {
    display: block;
    margin-top: -.5rem;
    padding: 0;

    i {
      font-size: 3.8rem;
    }
  }


}
