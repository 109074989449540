@import '../Mixins.scss';
.Terms {
  background: var(--unnamed-color-f7f7f7) 0% 0% no-repeat padding-box;
  padding: 30px;
  flex-direction: column;
  //align-self: stretch !important;

  .foot {
    display: flex;
    //background-color:  deepskyblue;
    flex-direction: column;
    margin-top: 60%;

    .assistance {
      display: flex;
      width: 100%;
    }

    .newrow {
      display: flex;
      margin-top: 50px;
      width: 100%;
      flex-direction: row;
      //background-color:  green;

      .left {
        display: flex;
        width: 50%;
        //background-color: red;
        img {
            width: 50%;
        }
      }

      .right {
        width: 50%;
        display: flex;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 32px/var(--unnamed-line-spacing-32) var(--unnamed-font-family-creattion);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-131731);
        text-align: left;
        @include respond-to('medium') {
          font-size: 70px;
        }
      }
    }

  }

  @include respond-to('medium') {
    //min-height: 100vh;
  }
}
