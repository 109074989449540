@import '../Mixins.scss';
.ImageBlock {
  height: 70vh;
  width: 100%;
  display: inline-flex;
  background-color: var(--unnamed-color-0b0e1d);
  vertical-align: top;
  left: 0;

  //&::before {
  //  content: '';
  //  position: absolute;
  //  top: 0;
  //  right: 0;
  //  bottom: 0;
  //  left: 0;
  //  background-color: rgba(14, 36, 54, .5); /* Adjust blue color and opacity as needed */
  //  mix-blend-mode: darken;
  //  pointer-events: none; /* This ensures that the overlay does not interfere with clicking or interacting with other elements */
  //}

  @include respond-to('medium') {
    height: 100vh;
    width: 50%;
    position: sticky!important;
    top: 0px;
  }

  .darkener {
    position: absolute;
    z-index: 0;
    width: 100%;
    //height: 70vh;
    //background-color: rgba(0, 0, 0, 0.6);
    @include respond-to('medium') {
      height: 100vh;
    }
  }

  .info {
    position: absolute;
    width: 100%;
    height: 75vh;
    @include respond-to('medium') {
      padding: 50px;
      height: 100vh;
    }

    .where {
      top: 40px;
      left: 30px;
      width: 70%;
      position: absolute;
      font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-36)/var(--unnamed-line-spacing-38) var(--unnamed-font-family-georgia);
      letter-spacing: var(--unnamed-character-spacing--0-36);
      color: var(--unnamed-color-f7f7f7);
      text-align: left;
      @include respond-to('medium') {
        font-size: 60px;
        line-height: 55px;
        width: 40%;
        left: 50px;
      }
    }

    .date {
      font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-cabinet-grotesk);
      letter-spacing: var(--unnamed-character-spacing-0);
      color: var(--unnamed-color-f7f7f7);
      width: 100%;
      text-align: left;
      @include respond-to('medium') {
        position: absolute;
        top: 0px;
        left: 110%;
        width: 100%;
        text-align: right;
      }
    }

    .day {
      top: 40px;
      right: 50px;
      position: absolute;
      font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-14) var(--unnamed-font-family-cabinet-grotesk);
      letter-spacing: var(--unnamed-character-spacing-0-28);
      color: var(--unnamed-color-f7f7f7);
      @include respond-to('medium') {
        left: 60%;
      }
    }
  }
}


/* Medium devices (tablets, 768px and up) */
@include respond-to('medium') {

}
