@import '../Mixins.scss';
.GlanceImage {
    height: 70vh;
    display: flex;
    padding: 0px;
    width: 100%;
    left: 0 !important;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 2; // Must be greater than the z-index of the mobile top and bottom navs
    background: white; // To make sure that the mobile top and bottom navs are not visible through the div

    @include respond-to('medium') {
        width: 50%!important;
        height: 100vh!important;
        position: sticky!important;
        top: 0px;
    }

    // the image who is the background
    .cont {
        width: 100% !important;
        display: flex;
        background: linear-gradient(to bottom, rgba(19, 23, 49, 0) 0%, rgba(19, 23, 49, 0) 10%, rgba(19, 23, 49, 1) 100%);
        @extend .filter-lens !optional;
        .when{
            display: flex;
            width: 50%;
            text-align: left;
            font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-18)/var(--unnamed-line-spacing-22) var(--unnamed-font-family-cabinet-grotesk);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-f7f7f7);
            padding: 20px;
            @include respond-to('medium') {
                font-size: 20px;
            }
        }

        .where{
            display: flex;
            width: 50%;

            font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-18)/var(--unnamed-line-spacing-22) var(--unnamed-font-family-cabinet-grotesk);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-f7f7f7);
            text-align: left;
            padding: 20px;
            @include respond-to('medium') {
                font-size: 20px;
            }
        }

    }


}
