@import "../../Mixins";

.VipAssistance {

  .vipassistance-title-container {
    font-size: 4rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;

    .vipassistance-title {
      text-align: left;
      font-size: 20px;
      letter-spacing: 0;
      color: #131731;
      opacity: 1;
      line-height: 2rem;
    }

    .vipassistance-icon {
      display: block;
      margin-top: -1rem;
      padding: 0;

      i {
        font-size: 4rem;
      }
    }
  }

  .vipassistance-content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;

    @include respond-to('small') {
      flex-direction: column;
    }

    .vipassistance-content-left {
      width: 50%;
      margin-right: 2rem;
      @include respond-to('small') {
        width: 100%;
      }
    }

    .vipassistance-content-right {
      width: 50%;
      @include respond-to('small') {
        width: 100%;
      }
    }
  }
}
