@import "../../Mixins";

$block-padding: 5px;

.BlockRenderer {
  position: relative;

  .blockrenderer-action-bar {
    text-align: right;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.3);
    padding: $block-padding;
    border-bottom-left-radius: 4px;

    @include respond-to('small') {
      display: none;
    }
    &.show {
      position: absolute;
      top: 0;
      right: 0;
      opacity: 0.3;
      transition: opacity 0.3s;
      &:hover {
        opacity: 1;
      }
    }
    &.hidden {
      border-bottom: 1px solid #ecedee;
      border-radius: 4px;
      padding: $block-padding;
      margin-bottom: 30px;
    }

    .blockrenderer-action {
      cursor: pointer;
    }

    .blockrenderer-placeholder {
      position: absolute;
      left: $block-padding;
      top: $block-padding;
      display: block;
      color: #a0a0a0;

      &.hidden {
        display: none;
      }
    }
  }

  .blockrenderer-content {
    &.hidden {
      display: none;
    }
    // Hiding the action bar when the block is empty
    &:empty + .blockrenderer-action-bar {
      display: none;
    }
  }
}