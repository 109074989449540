.Notifications {

  .panelo {
    position: fixed;
    left: 0;
    bottom: -100%; /* Start off-screen */
    width: 100%;
    text-align: center;
    vertical-align: middle;
    background-color: white;
    height: 10vh;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    transition: bottom 0.5s ease-in-out; /* Animation effect */
    z-index: 1000;
  }

  /* Control the visibility */
  .panelo-visible {
    bottom: 0;
  }



}
