:root {

    /* Colors: */
    --unnamed-color-0b0e1d: #0B0E1D;
    --unnamed-color-13173100: #13173100;
    --unnamed-color-f7f7f7: #F7F7F7;
    --unnamed-color-dddddd: #DDDDDD;
    --unnamed-color-ffffff: #FFFFFF;
    --unnamed-color-bec8da: #BEC8DA;
    --unnamed-color-131731: #131731;

    /* Font values */
    --unnamed-font-family-cabinet-grotesk: "CabinetGrotesk-Variable";
    --unnamed-font-family-georgia: Georgia;
    --unnamed-font-family-creattion: "Creattion";


    /* text values */
    --unnamed-font-style-normal: normal;


    --unnamed-font-weight-800: 800px;
    --unnamed-font-weight-normal: normal;
    --unnamed-font-weight-bold: bold;

    --unnamed-font-size-12: 12px;
    --unnamed-font-size-14: 14px;

    --unnamed-font-size-16: 16px;
    --unnamed-font-size-18: 18px;
    --unnamed-font-size-20: 20px;
    --unnamed-font-size-28: 28px;
    --unnamed-font-size-30: 30px;
    --unnamed-font-size-36: 36px;
    --unnamed-font-size-40: 40px;
    --unnamed-font-size-45: 45px;
    --unnamed-font-size-60: 60px;
    --unnamed-font-size-64: 64px;
    --unnamed-font-size-80: 80px;


    --unnamed-character-spacing-0: 0px;
    --unnamed-character-spacing-0-32: 0.32px;
    --unnamed-character-spacing-0-28: 0.28px;
    --unnamed-character-spacing--0-28: -0.28px;
    --unnamed-character-spacing--0-3: -0.3px;
    --unnamed-character-spacing--0-36: -0.36px;
    --unnamed-character-spacing--0-4: -0.4px;
    --unnamed-character-spacing--0-68: -0.68px;
    --unnamed-character-spacing--1-2: -1.2px;
    --unnamed-character-spacing--1-28: -1.28px;
    --unnamed-character-spacing--1-6: -1.6px;
    --unnamed-line-spacing-14: 14px;
    --unnamed-line-spacing-19: 19px;
    --unnamed-line-spacing-20: 20px;
    --unnamed-line-spacing-22: 22px;
    --unnamed-line-spacing-28: 28px;
    --unnamed-line-spacing-32: 32px;
    --unnamed-line-spacing-38: 38px;
    --unnamed-line-spacing-42: 42px;
    --unnamed-line-spacing-45: 45px;
    --unnamed-line-spacing-60: 60px;
    --unnamed-line-spacing-64: 64px;
    --unnamed-line-spacing-80: 80px;
    --unnamed-text-transform-uppercase: uppercase;


    --unnamed-padding-5: 30px;

    --unnamed-padding-10: 60px;



}
