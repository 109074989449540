@import "../../Mixins";

.BlockIncluding {
  font-size: 16px;
  text-align: left;
  letter-spacing: 0;
  color: #131731;

  @include respond-to('medium') {
    padding: 20px;
  }

  .blockincluding-title {
    font-weight: bold;
  }

  .blockincluding-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;

    .blockincluding-item {
      display: inline-flex;
      flex: 0 0 auto;
      gap: 0.5rem;
      width: 50%;
      margin-bottom: 0.5rem;

      .blockincluding-item-icon {
        margin-top: 0.3rem;
        font-size: 4rem;
        text-align: right;

        i {
          margin-left: -1.5rem;
          margin-right: 1rem;
        }
      }

      .blockincluding-item-text {
        text-transform: uppercase;
        align-items: center;
        min-height: 4rem;
        display: flex;
        margin-left: -1.5rem;
        margin-right: 1.5rem;
      }
    }
  }
}