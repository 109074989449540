.VersionChangeAlert {
  .toast {
    background-color: var(--unnamed-color-0b0e1d);
    color: var(--unnamed-color-ffffff) !important;
    font-weight: var(--unnamed-font-weight-800);
    width: 100%;
    border-radius: 0;

    .btn {
      border-radius: 2px;
    }
  }

  /* Medium devices (tablets, 768px and up) */
  @include respond-to('medium') {
    right: 0;
    left: auto !important;
    transform: none !important;
    .toast {
      border-radius: var(--bs-toast-border-radius);
    }
  }
}
